import React from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';

export default function ResendLink({ requestNewLink }) {
  return (
    <div className="login welcome" role="presentation">
      <div className="container">
        <Logo />
        <h1>Bienvenido!</h1>
        <h3>
          Te hemos enviado un correo con un link especial, revisa tu bandeja de entrada o en
          tus correos no deseados.
        </h3>
        <h3>
          ¿Aún no lo recibes? ¡Pide que te lo envíen ya!
        </h3>
        <button type="button" className="resend-btn" onClick={requestNewLink}>Envíar</button>
      </div>
    </div>
  );
}

ResendLink.propTypes = {
  requestNewLink: PropTypes.func.isRequired,
};
