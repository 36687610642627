import React from 'react';
import PropTypes from 'prop-types';

import GetEmailForm from '../containers/GetEmailForm';

export default function GetEmail({ msg }) {
  return (
    <div className="log-frm">
      <h1>Iniciar sesión</h1>
      <h3>del Directorio</h3>
      <GetEmailForm msg={msg} />
    </div>
  );
}

GetEmail.propTypes = {
  msg: PropTypes.string,
};

GetEmail.defaultProps = {
  msg: null,
};
