import * as ActionTypes from '../actions/actionsType';

export default (state = '', action) => {
  switch (action.type) {
    case ActionTypes.FLASH_MESSAGES:
      return { status: action.payload.status, msg: action.payload.msg };
    case ActionTypes.CREATED_PROFILE:
      return { status: action.payload.status, msg: action.payload.msg };
    case ActionTypes.CREATE_HERO_COMPANIES_SUCCESFULL:
      return { status: 201, msg: ['Company Created Successfuly!'] };
    case ActionTypes.UPDATE_HERO_COMPANIES_SUCCESFULL:
      return { status: 201, msg: ['Company Update Successfuly!'] };
    case ActionTypes.DESTROY_HERO_COMPANIES_SUCCESFULL:
      return { status: 201, msg: ['Company Deleted Successfuly!'] };
    case ActionTypes.CLEAR_FLASH_MESSAGES:
      return { status: null, msg: null };
    default:
      return state;
  }
};
