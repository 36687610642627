/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import {
  ClearFlashMessage, LogoutUser, changePwd,
} from '../actions/userAction';
import FlashMessages from '../components/FlashMessages';

// JavaScript
import focusText from '../javascripts/focusText';

export default function ResetPwdForm({ msg, code }) {
  const user = useSelector((state) => state.user);
  const [pwd, setPwd] = useState('');
  const [pwdConfi, setPwdConfi] = useState('');
  const dispatch = useDispatch();

  const formValidation = async (e) => {
    e.preventDefault();
    const login = user.email;
    const token = code;
    const form = e.target;
    const data = new FormData(form);
    const password = data.get('password');
    const password_confirmation = data.get('password_confirmation');
    if (!password || !password_confirmation) {
      dispatch(FlashMessages('all fields are mandatory'));
      return;
    }

    dispatch(changePwd({
      login, token, password, password_confirmation,
    }));
    form.reset();
  };

  const logout = () => {
    dispatch(LogoutUser());
  };

  const pwdHandler = (e) => {
    setPwd(e.target.value);
  };

  const pwdConfiHandler = (e) => {
    setPwdConfi(e.target.value);
  };

  useEffect(() => {
    dispatch(ClearFlashMessage());
  }, [dispatch]);

  const profileImg = user.profile ? user.profile : 'empty-profile.png';
  const errors = msg ? ' err' : '';
  return (
    <div className="pwd-frm">
      <div className={`email${errors}`}>
        <img src={profileImg} alt="hero" />
        <h3>{user.email}</h3>
      </div>
      <form action="/POST" onSubmit={formValidation} className="cd-err">
        { msg ? <FlashMessages msg={msg} /> : null}
        <div>
          <div>
            <label htmlFor="password" className={`info${pwd ? ' on' : ''}`} onClick={focusText} role="presentation">
              <span>Nueva contraseña</span>
              <input type="password" name="password" id="password" disabled={!user.loginAuthenticate} onChange={pwdHandler} />
            </label>
            <label htmlFor="password_confirmation" className={`info${pwdConfi ? ' on' : ''}`} onClick={focusText} role="presentation">
              <span>Confirmación de contraseña</span>
              <input type="password" name="password_confirmation" id="password_confirmation" disabled={!user.loginAuthenticate} onChange={pwdConfiHandler} />
            </label>
          </div>
        </div>
        <div className="btns">
          <label htmlFor="back">
            <input type="button" value="Go Back" onClick={logout} />
          </label>
          <label htmlFor="submit">
            <input type="submit" value="Iniciar sesión" />
          </label>
        </div>
      </form>
    </div>
  );
}

ResetPwdForm.propTypes = {
  msg: PropTypes.string,
  code: PropTypes.string.isRequired,
};

ResetPwdForm.defaultProps = {
  msg: null,
};
