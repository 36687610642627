import React, { useState, useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useForm } from 'react-hook-form';

import ImageUploader from './ImageUploader';
import Input from './Inputs';
import TextArea from './TextArea';
import Select from './Select';
import Logo from './Logo';

import getCountries from '../javascripts/getCountries';
import getProfessions from '../javascripts/getProfessions';
import { CreateProfile, GetProfile, UpdateProfile } from '../actions/profileAction';

const HeroProfileForm = ({
  img, registrated, setOpen, dispatch, msg,
}) => {
  const [file, setFile] = useState();
  const [countries, setCountries] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const {
    handleSubmit, register, errors, reset,
  } = useForm({
    defaultValues: {
      data,
    },
  });

  const onSubmit = (values) => {
    const profile = file || null;
    let formData = { ...values };
    if (profile) formData = { ...values, profile };
    if (registrated) {
      dispatch(UpdateProfile(formData));
    } else dispatch(CreateProfile(formData));
  };

  useEffect(() => {
    let mounted = true;

    if (countries.length === 0) {
      getCountries()
        .then((list) => {
          if (mounted) {
            setCountries(list);
          }
        });
    }

    if (professions.length === 0) {
      getProfessions('professions')
        .then((list) => {
          if (mounted) {
            setProfessions(list);
          }
        });
    }

    if (registrated) {
      GetProfile()
        .then((tempData) => {
          if (mounted) {
            setData(tempData);
            setLoaded(true);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [registrated, professions, countries]);

  useEffect(() => {
    if (registrated) reset({ ...data });
  }, [data, reset, registrated]);

  const errorsMessages = Object.values(errors);

  return (
    <div className={`profile ${errorsMessages ? 'error' : ''} ${!registrated ? 'registry' : ''}`}>
      <div className="container">
        {registrated ? <button type="button" className="close-btn" onClick={() => setOpen(false)}>x</button> : null}
        <Logo />
        <h2>Directorio Empresarial Comunitario</h2>
        <h1>servicios - comercios - profesionales</h1>
        <div className="errors">
          {errorsMessages.map((error) => <p key={error.message}>{error.message}</p>)}
          {msg?.msg?.map((message) => <p>{message}</p>)}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              <ImageUploader
                img={img}
                setFile={setFile}
                refClass={file || img ? ' on' : ''}
              />
            </div>
            <div className="brief">
              <TextArea
                name="brief"
                label="Breve descripción"
                fowardRef={register({ required: 'La breve descripción es obligatoria' })}
                loaded={loaded}
              />
            </div>
          </div>
          <div>
            <Input
              type="text"
              name="first_name"
              label="Nombres"
              fowardRef={register({ required: 'El nombre es obligatorio' })}
              loaded={loaded}
            />
            <Input
              type="text"
              name="last_name"
              label="Apellidos"
              fowardRef={register({ required: 'Los Apellidos son obligatorio' })}
              loaded={loaded}
            />
          </div>
          <div>
            <Select
              name="profession"
              label="Profesión"
              fowardRef={register({ required: 'Profeción es Obligatoria' })}
              data={professions}
              selected={data.profession_id}
            />
            <Select
              name="country"
              label="País"
              fowardRef={register({ required: 'País es Obligatorio' })}
              data={countries}
              selected={data.country_id}
            />
          </div>
          <div>
            <Input
              type="text"
              name="city"
              label="Ciudad"
              fowardRef={register({ required: 'Ciudad es Obligatoria' })}
              loaded={loaded}
            />
            <Input
              type="text"
              name="phone"
              label="Teléfono"
              fowardRef={register({
                pattern: { value: /(^[+][\s\-\d]*$)|(^\d[\s\-\d]*$)/, message: 'Teléfono invalidó' },
                required: 'el teléfono es obligatorio',
              })}
              loaded={loaded}
            />
          </div>
          <div className="submit">
            <input type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </div>
  );
};

HeroProfileForm.propTypes = {
  img: PropTypes.string,
  registrated: PropTypes.bool,
  setOpen: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  msg: PropTypes.objectOf(oneOfType([PropTypes.array, PropTypes.number])).isRequired,
};

HeroProfileForm.defaultProps = {
  img: null,
  registrated: null,
  setOpen: null,
};

export default HeroProfileForm;
