import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';
import barIcon from '../assets/images/bars-solid.svg';
import facebookIcon from '../assets/images/facebook.svg';
import instagramIcon from '../assets/images/instagram.svg';
import handshakeIcon from '../assets/images/handshake-regular.svg';
import signOutIcon from '../assets/images/sign-out-alt-solid.svg';
import internetIcon from '../assets/images/internet.svg';
import MediaIcon from './MediaIcon';

const Menu = () => {
  const [open, setOpen] = useState(false);
  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  const clickHandler = () => {
    setOpen(!open);
  };

  if (isMobile) {
    return (
      <div className={`menu${!beforeAnimation ? ' hidden' : ''}`} onAnimationStart={changeClass}>
        <button type="button" onClick={clickHandler}>
          <img src={barIcon} alt="menu" />
        </button>
        { open
          ? (
            <div className="submenu">
              <MediaIcon altRef="Hias" icon={internetIcon} target="https://www.hias.org/" />
              <MediaIcon altRef="Facebook" icon={facebookIcon} target="https://www.facebook.com/venezek-101184631821893" />
              <MediaIcon altRef="Instagram" icon={instagramIcon} target="https://www.instagram.com/venezek/" />
              <MediaIcon altRef="Terminos y Condiciones" icon={handshakeIcon} target="/agreements" text samePage />
              <MediaIcon altRef="Cerrar Cession" icon={signOutIcon} target="/auth/sign_out" text samePage />
            </div>
          )
          : null}
      </div>
    );
  }

  return (
    <div className={`menu${!beforeAnimation ? ' hidden' : ''}`} onAnimationStart={changeClass}>
      <button type="button" onClick={clickHandler}>
        <img src={barIcon} alt="menu" />
      </button>
      { open
        ? (
          <div className="submenu">
            <MediaIcon altRef="Terminos y Condiciones" icon={handshakeIcon} target="/agreements" text samePage />
            <MediaIcon altRef="Cerrar Cession" icon={signOutIcon} target="/auth/sign_out" text samePage />
          </div>
        )
        : null}
    </div>
  );
};

export default Menu;
