import React from 'react';

// Component
import Img from '../containers/Img';

// Assets
import LogoImg from '../assets/images/logo.svg';

export default function Logo() {
  return (
    <div className="logo">
      <Img imgAlt="Logo" imgUrl={LogoImg} imgClass="logo-img" />
    </div>
  );
}
