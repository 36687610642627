import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import Img from '../containers/Img';

import empty from '../assets/images/empty-logo.jpg';

const HeroCompany = ({
  company, setSelectedCompany,
}) => {
  const { logo, name } = company;
  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  const clickHandler = () => {
    setSelectedCompany({ ...company });
  };

  return (
    <div
      className={`hero-company${!beforeAnimation ? ' hidden' : ''}`}
      onAnimationStart={changeClass}
      role="presentation"
      onClick={clickHandler}
    >
      <Img imgAlt={name} imgUrl={logo || empty} />
      <h3>{name}</h3>
    </div>
  );
};

HeroCompany.propTypes = {
  company: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  setSelectedCompany: PropTypes.func.isRequired,
};

export default HeroCompany;
