import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import empty from '../assets/images/empty-profile.png';

const MobilePerson = ({ person, selected, selection }) => {
  const {
    profile, name, city, country, profession,
  } = person;

  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const clickHandler = () => {
    selected(null);
    selected(person);
  };

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  return (
    <div
      className={`mobile-company${person === selection ? ' active' : ''}${!beforeAnimation ? ' hidden' : ''}`}
      onAnimationStart={changeClass}
      onClick={clickHandler}
      role="presentation"
    >
      <div
        className="company"
      >
        <div className="image">
          <img src={profile || empty} alt="logo" />
        </div>
        <div className="info">
          <h2>{name}</h2>
          <p>{profession}</p>
          <span>{`${city} ${country}`}</span>
        </div>
      </div>
    </div>
  );
};

MobilePerson.propTypes = {
  person: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  selection: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])),
  selected: PropTypes.func.isRequired,
};

MobilePerson.defaultProps = {
  selection: null,
};

export default MobilePerson;
