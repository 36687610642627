import { composeWithDevTools } from 'redux-devtools-extension';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const initState = {
  loadingItems: 0,
  fetching: 0,
  user: {
    login: true,
    registrated: true,
    loginAuthenticate: false,
    email: null,
    profile: null,
  },
  flashMessages: { status: null, msg: null },
  heroCompanies: [],
  companies: [],
  ads: [],
  searchParams: null,
};

export default function configureStore() {
  const store = createStore(
    reducers,
    initState,
    composeWithDevTools(
      applyMiddleware(
        thunk,
      ),
    ),
  );
  return store;
}
