import React from 'react';
import { isMobile } from 'react-device-detect';
import MediaIcon from './MediaIcon';
import facebookIcon from '../assets/images/facebook.svg';
import instagramIcon from '../assets/images/instagram.svg';
import internetIcon from '../assets/images/internet.svg';
import Menu from './Menu';
// import linkedinIcon from '../assets/images/linkedin.svg';
// import pinterestIcon from '../assets/images/pinterest.svg';
// import twitterIcon from '../assets/images/twitter.svg';

const Icons = () => (
  <div className="media">
    {isMobile ? null : (
      <>
        <MediaIcon altRef="link" icon={internetIcon} target="https://www.hias.org/" />
        <MediaIcon altRef="facebook" icon={facebookIcon} target="https://www.facebook.com/venezek-101184631821893" />
        <MediaIcon altRef="instagram" icon={instagramIcon} target="https://www.instagram.com/venezek/" />
      </>
    )}
    <Menu />
    {/* <MediaIcon altRef="pinterest" icon={pinterestIcon} target="/" />
    <MediaIcon altRef="twitter" icon={twitterIcon} target="/" />
    <MediaIcon altRef="linkedin" icon={linkedinIcon} target="/" /> */}
  </div>
);

export default Icons;
