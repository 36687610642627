import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

const Fetching = () => (
  <div className="fetching">
    <svg>
      <defs>
        <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="0%" stopColor="#889cbb" stopOpacity="50%" />
          <stop offset="100%" stopColor="#1a1449" stopOpacity="100%" />
        </radialGradient>
      </defs>
      <circle cx="10" cy="10" r="5" strokeWidth="3" fill="url(#grad1)" />
    </svg>
    <svg>
      <circle cx="10" cy="10" r="5" strokeWidth="3" fill="url(#grad1)" />
    </svg>
    <svg>
      <circle cx="10" cy="10" r="5" strokeWidth="3" fill="url(#grad1)" />
    </svg>
  </div>
);

const Loading = () => {
  const fetching = useSelector((state) => state.fetching);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (fetching > 0) {
      document.body.classList.add('stop');
      setOpen(true);
    } else {
      setOpen(false);
      document.body.classList.remove('stop');
    }
  }, [fetching]);
  return (
    <>
      {open ? <Fetching /> : null}
    </>

  );
};

export default Loading;
