import React, { useState, useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';

import { ClearFlashMessage } from '../actions/userAction';

import empty from '../assets/images/empty-profile.png';

import Img from '../containers/Img';
import HeroProfileForm from './HeroProfileForm';

const HeroProfileSection = ({ hero, flashMessages, dispatch }) => {
  const [openProfile, setOpenProfile] = useState(false);

  const {
    fullname, profession, brief, profile, registrated, login,
  } = hero;

  useEffect(() => {
    if (!registrated && login) {
      setOpenProfile(true);
    } else setOpenProfile(false);
  }, [registrated, setOpenProfile, login]);

  useEffect(() => {
    const { status } = flashMessages;
    if (openProfile && status >= 200 && status <= 204 && profile) {
      dispatch(ClearFlashMessage());
      setOpenProfile(false);
    }
  }, [flashMessages, dispatch, openProfile, profile, setOpenProfile]);

  useEffect(() => {
    const { body } = document;
    if (openProfile) {
      return body.classList.add('stop');
    }
    return body.classList.remove('stop');
  }, [openProfile]);

  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  const clickHandler = () => {
    setOpenProfile(true);
  };

  return (
    <div className={`hero-profile-section${!beforeAnimation ? ' hidden' : ''}`} onAnimationStart={changeClass}>
      {!openProfile
        ? null
        : (
          <HeroProfileForm
            img={profile}
            registrated={registrated}
            setOpen={setOpenProfile}
            dispatch={dispatch}
            msg={flashMessages}
          />
        )}
      <div onClick={clickHandler} role="presentation" className="click">
        <div className="main-hero-img">
          <Img imgAlt="hero-img" imgUrl={profile || empty} />
        </div>
        <h3>{fullname}</h3>
      </div>

      <div className="hero-info">
        <p>{profession}</p>
        <p>{brief}</p>
      </div>
    </div>
  );
};

HeroProfileSection.propTypes = {
  hero: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
  dispatch: PropTypes.func.isRequired,
  flashMessages: PropTypes.objectOf(oneOfType([PropTypes.array, PropTypes.number])).isRequired,
};

export default HeroProfileSection;
