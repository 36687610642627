import * as ActionTypes from '../actions/actionsType';

export default (state = '', action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPANIES_SUCCESFULL:
      return action.payload;
    case ActionTypes.GET_MORE_COMPANIES_SUCCESFULL:
      return [...state, ...action.payload];
    default:
      return state;
  }
};
