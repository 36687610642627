import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

const MediaIcon = ({
  icon, altRef, target, text, samePage,
}) => {
  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  return (
    <div className={`media-icon${!beforeAnimation ? ' hidden' : ''}`} onAnimationStart={changeClass}>
      <a target={samePage ? '' : '_blank'} href={target} rel="noreferrer noopener">
        <img src={icon} alt={altRef} />
        { isMobile || text ? altRef : null}
      </a>
    </div>
  );
};

MediaIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  altRef: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  text: PropTypes.bool,
  samePage: PropTypes.bool,
};

MediaIcon.defaultProps = {
  text: false,
  samePage: false,
};

export default MediaIcon;
