import * as ActionTypes from './actionsType';

const getAdsSuccesfull = (json) => ({
  type: ActionTypes.GET_ADS_SUCCESFULL,
  payload: json,
});

const GetAds = () => {
  const url = '/api/v1/ads';
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };
  return (dispatch) => {
    fetch(url, config)
      .then((response) => response.json())
      .then((json) => dispatch(getAdsSuccesfull(json)))
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
};

export default GetAds;
