import React from 'react';
import PropTypes from 'prop-types';

const AdsSliderElement = ({ imgSrc, link, refClass }) => (
  <a className={`slide-img${refClass}`} href={link} target="_blank" rel="noopener noreferrer">
    <img src={imgSrc} alt="Slide Img" />
  </a>
);

AdsSliderElement.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  refClass: PropTypes.string.isRequired,
};

export default AdsSliderElement;
