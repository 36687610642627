import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import empty from '../assets/images/empty-logo.jpg';
import SelectionMedia from './SelectionMedia';

const MobileCompany = ({ company, selected, selection }) => {
  const {
    logo, name, city, country, profession, brief, facebook, instagram, url,
  } = company;

  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const clickHandler = () => {
    selected(company);
  };

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  return (
    <div
      className={`mobile-company${company === selection ? ' active' : ''}${!beforeAnimation ? ' hidden' : ''}`}
      onAnimationStart={changeClass}
      onClick={clickHandler}
      role="presentation"
    >
      <div
        className="company"
      >
        <div className="image">
          <img src={logo || empty} alt="logo" />
        </div>
        <div className="info">
          <h2>{name}</h2>
          <p>{profession}</p>
          <span>{`${city} ${country}`}</span>
        </div>
      </div>
      <div className="more-info">
        <div className="info">
          <p>{brief}</p>
        </div>
        <SelectionMedia url={url} facebook={facebook} instagram={instagram} />
      </div>
    </div>
  );
};

MobileCompany.propTypes = {
  company: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  selection: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])),
  selected: PropTypes.func.isRequired,
};

MobileCompany.defaultProps = {
  selection: null,
};

export default MobileCompany;
