import React, { useState, useEffect, useRef } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useForm } from 'react-hook-form';

import ImageUploader from './ImageUploader';
import Input from './Inputs';
import TextArea from './TextArea';
import Select from './Select';
import Logo from './Logo';

import getCountries from '../javascripts/getCountries';
import getProfessions from '../javascripts/getProfessions';
import { CreateHeroCompany, DeleteHeroCompany, UpdateHeroCompany } from '../actions/companiesAction';

const HeroCompanyForm = ({
  setOpen, dispatch, msg, selectedCompany,
}) => {
  const [file, setFile] = useState();
  const [countries, setCountries] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const {
    handleSubmit, register, errors, reset,
  } = useForm({
    defaultValues: {
      ...selectedCompany,
    },
  });

  const onSubmit = (values) => {
    const logo = file || null;
    const { country, profession } = values;
    // eslint-disable-next-line camelcase
    const country_id = parseInt(country, 10);
    // eslint-disable-next-line camelcase
    const profession_id = parseInt(profession, 10);
    let formData = { ...values, country_id, profession_id };
    if (logo) formData = { ...formData, logo };
    if (selectedCompany) {
      dispatch(UpdateHeroCompany(formData, selectedCompany.id));
    } else dispatch(CreateHeroCompany(formData));
  };

  const countRef = useRef(0);
  useEffect(() => {
    let mounted = true;
    if (countries.length === 0) {
      getCountries()
        .then((list) => {
          if (mounted) {
            setCountries(list);
          }
        });
    }

    if (professions.length === 0) {
      getProfessions('companies')
        .then((list) => {
          if (mounted) {
            setProfessions(list);
          }
        });
    }

    return () => {
      countRef.current += 1;
      mounted = false;
    };
  }, [professions, countries]);

  const destroyHandler = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('¿Estas seguro que quieres eliminar esta compañia?')) {
      dispatch(DeleteHeroCompany(selectedCompany.id));
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      setData(selectedCompany);
      setLoaded(true);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (!selectedCompany) reset({ ...data });
  }, [data, reset, selectedCompany]);

  const img = selectedCompany ? selectedCompany.logo : null;
  const errorsMessages = Object.values(errors);
  return (
    <div className={`profile${errorsMessages ? ' error' : ''}`}>
      <div className="container">
        <button type="button" className="close-btn" onClick={() => setOpen(false)}>x</button>
        <Logo />
        <h2>Directorio Empresarial Comunitario</h2>
        <h1>servicios - comercios - profesionales</h1>
        <div className="errors">
          {errorsMessages.map((error) => <p key={error.message}>{error.message}</p>)}
          {msg?.msg?.map((message) => <p>{message}</p>)}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              <ImageUploader
                img={img}
                setFile={setFile}
                refClass={file || img ? ' on' : ''}
              />
            </div>
            <div className="brief">
              <TextArea
                name="brief"
                label="Breve descripción"
                fowardRef={register({ required: 'La breve descripción es obligatoria' })}
                loaded={loaded}
              />
            </div>
          </div>
          <div>
            <Input
              type="text"
              name="name"
              label="Nombres de Compañía"
              fowardRef={register({ required: 'El nombre es obligatorio' })}
              loaded={loaded}
            />
            <Select
              name="profession"
              label="Rubro"
              fowardRef={register({ required: 'Rubro es Obligatorio' })}
              data={professions}
              selected={data.profession_id}
            />
          </div>
          <div>
            <Select
              name="country"
              label="País"
              fowardRef={register({ required: 'País es Obligatorio' })}
              data={countries}
              selected={data.country_id}
            />
            <Input
              type="text"
              name="city"
              label="Ciudad"
              fowardRef={register({ required: 'Ciudad es Obligatoria' })}
              loaded={loaded}
            />
          </div>
          <div>
            <Input
              type="text"
              name="phone"
              label="Teléfono"
              fowardRef={register({
                pattern: { value: /(^[+][\s\-\d]*$)|(^\d[\s\-\d]*$)/, message: 'Teléfono invalidó' },
                required: 'el teléfono es obligatorio',
              })}
              loaded={loaded}
            />
            <Input
              type="text"
              name="url"
              label="Página Web"
              fowardRef={register()}
              loaded={loaded}
            />
          </div>
          <div>
            <Input
              type="text"
              name="facebook"
              label="Facebook"
              fowardRef={register()}
              loaded={loaded}
            />
            <Input
              type="text"
              name="instagram"
              label="Instagram"
              fowardRef={register()}
              loaded={loaded}
            />
          </div>
          <div className="submit">
            {selectedCompany
              ? <input type="button" value="Eliminar" className="destroy" onClick={destroyHandler} />
              : null}
            <input type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </div>
  );
};

HeroCompanyForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  msg: PropTypes.objectOf(oneOfType([PropTypes.array, PropTypes.number])).isRequired,
  selectedCompany: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])),
};

HeroCompanyForm.defaultProps = {
  selectedCompany: null,
};

export default HeroCompanyForm;
