import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MobileCompany from './MobileCompany';
import { GetMoreCompanies } from '../actions/companiesAction';
import MobilePerson from './MobilePerson';

const MobileCompanyList = ({ companies, setComapny, selection }) => {
  const searchParams = useSelector((state) => state.searchParams);

  const [style, setStyle] = useState();
  const dispatch = useDispatch();
  const listOfCompanies = companies
    .map((company) => (
      company.type === 1
        ? (
          <MobileCompany
            company={company}
            selected={setComapny}
            selection={selection}
            key={`${company.name}-${company.id}`}
          />
        )
        : (
          <MobilePerson
            person={company}
            selected={setComapny}
            selection={selection}
            key={`${company.name}-${company.id}`}
          />
        )
    ));

  const pageCounter = useRef(2);
  const eventListeners = useRef();
  const scrollHandler = useCallback((params) => {
    const { current } = pageCounter;

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      dispatch(GetMoreCompanies(params, current));
      pageCounter.current += 1;
    }
  }, [dispatch]);

  useEffect(() => {
    window.removeEventListener('scroll', eventListeners.current, true);
    eventListeners.current = () => scrollHandler(searchParams);
    window.addEventListener('scroll', eventListeners.current, true);
  }, [companies.length, scrollHandler, searchParams]);

  useEffect(() => {
    setTimeout(() => {
      setStyle({
        height: `${(companies.length * 110) + 300}px`,
      });
    }, 1300);
  }, [setStyle, companies.length]);

  return (
    <div className="companies" style={style}>
      {listOfCompanies}
    </div>
  );
};

MobileCompanyList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  setComapny: PropTypes.func.isRequired,
  selection: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])),
};

MobileCompanyList.defaultProps = {
  selection: null,
};

export default MobileCompanyList;
