import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { GetCompanies } from '../actions/companiesAction';
import CompaniesList from '../components/CompaniesList';
import SelectedCompany from '../components/SelectedCompany';
import SelectedPerson from '../components/SelectedPerson';
import MobileCompanyList from '../components/MobileCompanyList';

const CompaniesSection = () => {
  const companies = useSelector((state) => state.companies);
  const searchParams = useSelector((state) => state.searchParams);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [transitionClass, setTransitionClass] = useState('');

  const dispatch = useDispatch();
  const counter = useRef(0);
  useEffect(() => {
    if (counter.current > 1) return;
    if (companies.length === 0) {
      dispatch(GetCompanies(searchParams));
    } else {
      setSelectedCompany(companies[0]);
    }
    counter.current += 1;
  }, [companies, dispatch, searchParams]);

  const selectedCompanyHandler = (company) => {
    setTransitionClass(' fade');
    setTimeout(() => {
      setTransitionClass(' in');
      setSelectedCompany(company);
    }, 300);
  };

  const mobileSelectedCompanyHandler = (company) => {
    setSelectedCompany(company);
  };

  if (!companies) return null;

  return (
    <>
      <main>
        {
          isMobile
            ? (
              <MobileCompanyList
                companies={companies}
                setComapny={mobileSelectedCompanyHandler}
                selection={selectedCompany}
              />
            )
            : (
              <CompaniesList
                companies={companies}
                setCompany={selectedCompanyHandler}
              />
            )
        }
      </main>
      <div className="selection">
        {selectedCompany && !isMobile
          ? (
            <div className="sticky">
              {selectedCompany.type === 1
                ? <SelectedCompany company={selectedCompany} transitionClass={transitionClass} />
                : <SelectedPerson person={selectedCompany} transitionClass={transitionClass} />}
            </div>
          )
          : null}
      </div>
    </>
  );
};

export default CompaniesSection;
