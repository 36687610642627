import React from 'react';
import PropTypes from 'prop-types';
import MediaIcon from './MediaIcon';
import facebookIcon from '../assets/images/facebook.svg';
import instagramIcon from '../assets/images/instagram.svg';
import internetIcon from '../assets/images/internet.svg';

const SelectionMedia = ({ facebook, instagram, url }) => (
  <div className="media">
    {facebook ? (
      <MediaIcon
        altRef="facebook"
        icon={facebookIcon}
        target={facebook}
      />
    ) : null}

    {instagram ? (
      <MediaIcon
        altRef="facebook"
        icon={instagramIcon}
        target={instagram}
      />
    ) : null}

    {url ? (
      <MediaIcon
        altRef="facebook"
        icon={internetIcon}
        target={url}
      />
    ) : null}
  </div>
);

SelectionMedia.propTypes = {
  instagram: PropTypes.string,
  facebook: PropTypes.string,
  url: PropTypes.string,
};

SelectionMedia.defaultProps = {
  facebook: null,
  instagram: null,
  url: null,
};

export default SelectionMedia;
