import * as ActionTypes from '../actions/actionsType';

export default (state = '', action) => {
  switch (action.type) {
    case ActionTypes.SET_GET_PARAMS:
      return action.payload;
    default:
      return state;
  }
};
