import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { GetCompanies, SetParams } from '../actions/companiesAction';

import Input from '../components/Inputs';
import searchIcon from '../assets/images/search.svg';

const SearchForm = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const onSubmit = ({ search }) => {
    dispatch(GetCompanies(search));
    dispatch(SetParams(search));
  };

  return (
    <div className="search-form">
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              type="text"
              name="search"
              label="Búsqueda"
              fowardRef={register()}
              icon={searchIcon}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchForm;
