import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { CloseSession } from '../../actions/userAction';

const SignOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CloseSession());
  }, [dispatch]);

  return (
    <Redirect to="/login" />
  );
};

export default SignOut;
