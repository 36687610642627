import * as ActionTypes from '../actions/actionsType';

export default (state = '', action) => {
  const newState = [];
  switch (action.type) {
    case ActionTypes.GET_HERO_COMPANIES_SUCCESFULL:
      return action.payload;
    case ActionTypes.CREATE_HERO_COMPANIES_SUCCESFULL:
      return [...state, action.payload];
    case ActionTypes.UPDATE_HERO_COMPANIES_SUCCESFULL:
      state.map((company) => {
        if (company.id === action.payload.id) {
          return newState.push(action.payload);
        }
        return newState.push(company);
      });
      return newState;
    case ActionTypes.DESTROY_HERO_COMPANIES_SUCCESFULL:
      state.map((company) => {
        if (company.id === action.payload) {
          return {};
        }
        return newState.push(company);
      });
      return newState;
    default:
      return state;
  }
};
