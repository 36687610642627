import React from 'react';
import PropTypes from 'prop-types';
import GetEmailForm from '../containers/GetEmailForm';

export default function RecoverPwd({ msg }) {
  return (
    <div className="log-frm">
      <h1>¿Se te olvidó tu contraseña?</h1>
      <h3>Le enviaremos un enlace para restablecer su contraseña</h3>
      <GetEmailForm msg={msg} />
    </div>
  );
}

RecoverPwd.propTypes = {
  msg: PropTypes.string,
};

RecoverPwd.defaultProps = {
  msg: null,
};
