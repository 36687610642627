import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroCompaniesSection from '../components/HeroCompaniesSection';
import HeroProfileSection from '../components/HeroProfileSection';

const LeftSlider = () => {
  const dispatch = useDispatch();
  const flashMessages = useSelector((state) => state.flashMessages);
  const user = useSelector((state) => state.user);
  const heroCompanies = useSelector((state) => state.heroCompanies);
  return (
    <div className="hero-profile">
      <div className="stick">
        <HeroProfileSection
          hero={user}
          dispatch={dispatch}
          flashMessages={flashMessages}
        />
        <HeroCompaniesSection
          companies={heroCompanies}
          dispatch={dispatch}
          flashMessages={flashMessages}
        />
      </div>
    </div>
  );
};

export default LeftSlider;
