import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import focusText from '../javascripts/focusText';

const TextArea = ({
  name, fowardRef, label, id, loaded,
}) => {
  const [input, setInput] = useState('');

  const inputHandler = (e) => {
    setInput(e.target.value);
  };

  const newRef = useCallback((node) => {
    if (node !== null) {
      setInput(node.value);
      fowardRef(node);
    }
  }, [fowardRef]);

  useEffect(() => {
    const tempInput = document.getElementsByName(name);
    if (tempInput[0].value) setInput(tempInput[0].value);
  }, [loaded, name]);

  return (
    <label htmlFor={name} className={`info${input ? ' on' : ''}`} onClick={focusText} role="presentation">
      <span>{label}</span>
      <textarea
        name={name}
        id={id}
        onChange={inputHandler}
        ref={newRef}
      />
    </label>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  id: PropTypes.string,
  fowardRef: PropTypes.func,
};

TextArea.defaultProps = {
  id: null,
  fowardRef: null,
};

export default TextArea;
