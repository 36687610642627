import * as ActionTypes from '../actions/actionsType';

export default (state = '', action) => {
  switch (action.type) {
    case ActionTypes.VALIDATE_USER_PASSWORD:
      return { ...state, login: true, registrated: true };
    case ActionTypes.VALIDATE_USER_LOGIN:
      return {
        loginAuthenticate: true,
        email: action.payload.email,
        profile: action.payload.profile,
        validated: action.payload.validated,
      };
    case ActionTypes.CREATED_PROFILE:
      return {
        ...state,
        profile: action.payload.image,
        registrated: true,
        fullname: action.payload.fullname,
        brief: action.payload.brief,
        profession: action.payload.profession,
      };
    case ActionTypes.UNVALIDATE_USER_LOGIN:
      return {
        loginAuthenticate: true,
        email: action.payload.email,
        profile: action.payload.profile,
        validated: action.payload.validated,
      };
    case ActionTypes.AUTHENTICATE_USER_CREDENTIALS:
      return {
        loginAuthenticate: true,
        email: action.payload.email,
        profile: action.payload.profile,
        registrated: action.payload.registrated,
        login: true,
        fullname: action.payload.fullname,
        brief: action.payload.brief,
        profession: action.payload.profession,
      };
    case ActionTypes.LOGOUT_USER:
      return {
        login: false,
        loginAuthenticate: false,
        email: null,
        profile: null,
        registrated: true,
      };
    case ActionTypes.SEND_RESET_PASSWORD:
      return { ...state, resetEmail: true };
    default:
      return state;
  }
};
