import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { LoginValidation, FlashMessage } from '../actions/userAction';
import FlashMessages from '../components/FlashMessages';

// JavaScript
import focusText from '../javascripts/focusText';

export default function GetEmailForm({ msg }) {
  const user = useSelector((state) => state.user);
  const [input, setInput] = useState('');
  const dispatch = useDispatch();

  const formValidation = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const login = data.get('login');
    if (!login) {
      dispatch(FlashMessage(401, 'Username or Email is Required'));
      return;
    }
    dispatch(LoginValidation({ login }));
    form.reset();
    setInput('');
  };

  const inputHandler = (e) => {
    setInput(e.target.value);
  };

  return (
    <form action="/POST" onSubmit={formValidation}>
      { msg ? <FlashMessages msg={msg} /> : null}
      <div>
        <div>
          <label htmlFor="login" className={`info${input ? ' on' : ''}`} onClick={focusText} role="presentation">
            <span>Correo electrónico o usuario</span>
            <input type="text" name="login" id="username" disabled={user.loginAuthenticate} onChange={inputHandler} />
          </label>
        </div>
      </div>
      <div className="btns">
        <label htmlFor="submit">
          <input type="submit" value="Siguiente" />
        </label>
      </div>
    </form>
  );
}

GetEmailForm.propTypes = {
  msg: PropTypes.string,
};

GetEmailForm.defaultProps = {
  msg: null,
};
