import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import GetAds from '../actions/adsAction';
import AdsSliderContainer from '../components/AdsSliderContainer';

const Ads = () => {
  const listOfAds = useSelector((state) => state.ads);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAds());
  }, [dispatch]);

  if (!listOfAds.length) return null;

  return (
    <div className="ads">
      <AdsSliderContainer arrayOfAds={listOfAds} />
    </div>
  );
};
export default Ads;
