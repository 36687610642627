import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { GetHeroCompanies } from '../actions/companiesAction';

// Action
import {
  GetUserFromToken,
} from '../actions/userAction';
import Icons from '../components/Icons';
import SearchBar from '../components/SearchBar';
import Ads from '../containers/Ads';
import CompaniesSection from '../containers/CompaniesSection';

// Components
import LeftSlider from '../containers/LeftSlider';

// Assets
import upImg from '../assets/images/up.svg';
import HeroProfileForm from '../components/HeroProfileForm';

export default function Home() {
  const user = useSelector((state) => state.user);
  const flashMessages = useSelector((state) => state.flashMessages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetUserFromToken());
  }, [dispatch]);

  useEffect(() => {
    if (user.loginAuthenticate) {
      dispatch(GetHeroCompanies());
    }
  }, [user.loginAuthenticate, dispatch]);

  if (!user.login) {
    return <Redirect to="/login" />;
  }

  const clickHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const { registrated } = user;

  if (!user.loginAuthenticate) return null;

  if (!registrated) return (<HeroProfileForm dispatch={dispatch} msg={flashMessages} />);

  return (
    <div className={`app${isMobile ? ' mobile' : ''}`}>
      <header className="app-header">
        <div>
          <SearchBar />
          <Icons />
        </div>
      </header>
      <Ads />
      {isMobile ? null : <LeftSlider />}
      <CompaniesSection />
      <div className="get-to-top">
        <button type="button" onClick={clickHandler}>
          <img src={upImg} alt="Got to the top" />
        </button>
      </div>
    </div>
  );
}
