import React, { useEffect, useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';

import AddActive from './AddActive';
import HeroCompaniesList from './HeroCompaniesList';
import CompanyForm from './HeroCompanyForm';
import { ClearFlashMessage } from '../actions/userAction';

const HeroCompaniesSection = ({ companies, flashMessages, dispatch }) => {
  const [companyForm, setCompanyForm] = useState(false);
  const [beforeAnimation, setBeforeAnimation] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  useEffect(() => {
    const { status } = flashMessages;
    if (companyForm && status >= 200 && status <= 204) {
      dispatch(ClearFlashMessage());
      setCompanyForm(false);
    }
  }, [flashMessages, dispatch, companyForm, setCompanyForm]);

  useEffect(() => {
    const { body } = document;
    if (companyForm) {
      return body.classList.add('stop');
    }
    setSelectedCompany(null);
    return body.classList.remove('stop');
  }, [companyForm]);

  useEffect(() => {
    if (selectedCompany) {
      setCompanyForm(true);
    }
  }, [selectedCompany]);

  return (
    <div className={`hero-companies-section${!beforeAnimation ? ' hidden' : ''}`} onAnimationStart={changeClass}>
      {companyForm
        ? (
          <CompanyForm
            setOpen={setCompanyForm}
            dispatch={dispatch}
            msg={flashMessages}
            selectedCompany={selectedCompany}
          />
        )
        : null}
      <AddActive text="Agregar compañía o servicio" func={setCompanyForm} />
      <HeroCompaniesList
        heroCompaniesList={companies}
        setSelectedCompany={setSelectedCompany}
      />
    </div>
  );
};

HeroCompaniesSection.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  flashMessages: PropTypes.objectOf(oneOfType([PropTypes.array, PropTypes.number])).isRequired,
};

export default HeroCompaniesSection;
