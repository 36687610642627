import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import focusText from '../javascripts/focusText';
import Options from './Options';
import { PredictionText, SelectFunctionalities } from '../javascripts/select';

const Select = ({
  name, fowardRef, label, id, data, selected,
}) => {
  const [input, setInput] = useState('');
  const [open, setOpen] = useState(false);
  const [firstText, setFirstText] = useState([]);
  const [list, setList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const inputHandler = (e) => {
    const { target: { value } } = e;
    const selectedValue = data.filter((obj) => {
      const keys = Object.keys(obj);
      return obj[keys[1]] === value;
    })[0];
    if (selectedValue) {
      setInput(value);
      setInputValue(selectedValue.id);
    } else {
      setInput(value);
      setInputValue('');
    }
  };

  const eventHandler = (e) => {
    SelectFunctionalities(e);
  };

  const closePrediction = () => {
    setOpen(false);
    setFirstText('');
  };

  const windowsHandler = (e) => {
    if (!e.target.classList.contains('option')) {
      window.removeEventListener('click', windowsHandler, true);
      closePrediction();
    }
  };

  const getSelectedValue = useCallback(async () => {
    if (selected && data) {
      const filtered = await data.filter((n) => n.id === selected)[0];
      if (filtered) {
        const keys = Object.keys(filtered);
        setInputValue(filtered.id);
        setInput(filtered[keys[0]]);
      }
    }
  }, [selected, data]);

  const openPrediction = () => {
    setOpen(true);
    window.addEventListener('click', windowsHandler, true);
  };

  useEffect(() => {
    const prediction = PredictionText(input, data);

    setList(prediction.newList);
    setFirstText(prediction.firstPrediction[0]);
  }, [input, data]);

  useEffect(() => {
    getSelectedValue();
  }, [getSelectedValue]);

  return (
    <label
      htmlFor={name}
      className={`select info${input ? ' on' : ''}`}
      onClick={(e) => { focusText(e); openPrediction(e); }}
      role="presentation"
      onKeyDown={eventHandler}
      onLostPointerCapture={closePrediction}
      onFocus={(e) => { focusText(e); openPrediction(e); }}
    >
      <span>{label}</span>
      <input
        name={`${name}-display`}
        type="text"
        className="text"
        onChange={inputHandler}
        value={input}
        autoComplete={name}
      />
      <input
        type="text"
        name={name}
        className="hidden"
        id={id}
        ref={fowardRef}
        tabIndex="-1"
        value={inputValue}
        readOnly
      />
      {open || input
        ? (
          <Options
            data={list}
            input={input}
            setInput={setInput}
            setFirstText={setFirstText}
            setInputValue={setInputValue}
          />
        )
        : null}
      {firstText ? (
        <input className="predicted-text" type="text" value={firstText} disabled />
      ) : null}
    </label>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.number,
  id: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fowardRef: PropTypes.func,
};

Select.defaultProps = {
  id: null,
  fowardRef: null,
  selected: null,
};

export default Select;
