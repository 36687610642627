import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import SelectionMedia from './SelectionMedia';

import empty from '../assets/images/empty-logo.jpg';

const SelectedCompany = ({ company, transitionClass }) => {
  const {
    logo, name, city, country, profession, brief, facebook, url, instagram,
  } = company;
  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  return (
    <div
      onAnimationStart={changeClass}
      className={`selected-company${!beforeAnimation ? ' hidden' : ''}${transitionClass}`}
    >
      <div className="image">
        <img src={logo || empty} alt="logo" />
      </div>
      <div className="info">
        <h2>{name}</h2>
        <p>{brief}</p>
        <p>{profession}</p>
        <span>{`${city} ${country}`}</span>
      </div>
      <SelectionMedia facebook={facebook} instagram={instagram} url={url} />
    </div>
  );
};

SelectedCompany.propTypes = {
  company: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  transitionClass: PropTypes.string.isRequired,
};

export default SelectedCompany;
