import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import empty from '../assets/images/empty-profile.png';

// Actions
import {
  FlashMessage, ClearFlashMessage, LoginUser, LogoutUser,
} from '../actions/userAction';
import FlashMessages from '../components/FlashMessages';

// JavaScript
import focusText from '../javascripts/focusText';

export default function LoginUserForm({ msg }) {
  const user = useSelector((state) => state.user);
  const [input, setInput] = useState('');
  const dispatch = useDispatch();

  const formValidation = async (e) => {
    const login = user.email;
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const password = data.get('password');
    if (!password) {
      dispatch(FlashMessage(401, 'Password is Required'));
      return;
    }
    dispatch(LoginUser({ password, login }));
    form.reset();
    setInput('');
  };

  const logout = () => {
    dispatch(ClearFlashMessage());
    dispatch(LogoutUser());
  };

  const inputHandler = (e) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    dispatch(ClearFlashMessage());
  }, [dispatch]);

  useEffect(() => {
    if (user.loginAuthenticate) {
      setTimeout(() => {
        const pwd = document.getElementById('password');
        if (pwd) {
          pwd.focus();
        }
      }, 1500);
    }
  }, [user.loginAuthenticate]);

  const profileImg = user.profile ? user.profile : empty;
  return (
    <div className="pwd-frm">
      <div className="email">
        <img src={profileImg} alt="hero" />
        <h3>{user.email}</h3>
      </div>
      <form action="/POST" onSubmit={formValidation}>
        { msg ? <FlashMessages msg={msg} specialClass='pass' /> : null}
        <div>
          <div>
            <label htmlFor="password" className={`info${input ? ' on' : ''}`} onClick={focusText} role="presentation">
              <span>Contraseña</span>
              <input type="password" name="password" id="password" disabled={!user.loginAuthenticate} onChange={inputHandler} />
            </label>
          </div>
        </div>
        <div className="btns">
          <label htmlFor="back">
            <input type="button" value="Regresar" onClick={logout} />
          </label>
          <label htmlFor="submit">
            <input type="submit" value="Iniciar sesión" />
          </label>
        </div>
      </form>
    </div>
  );
}

LoginUserForm.propTypes = {
  msg: PropTypes.string,
};

LoginUserForm.defaultProps = {
  msg: null,
};
