import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import empty from '../assets/images/empty-profile.png';

const SelectedPerson = ({ person, transitionClass }) => {
  const {
    profile, name, city, country, profession, brief,
  } = person;
  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  return (
    <div
      onAnimationStart={changeClass}
      className={`selected-company${!beforeAnimation ? ' hidden' : ''}${transitionClass}`}
    >
      <div className="image">
        <img src={profile || empty} alt="logo" />
      </div>
      <div className="info">
        <h2>{name}</h2>
        <p>{brief}</p>
        <p>{profession}</p>
        <span>{`${city} ${country}`}</span>
      </div>
    </div>
  );
};

SelectedPerson.propTypes = {
  person: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  transitionClass: PropTypes.string.isRequired,
};

export default SelectedPerson;
