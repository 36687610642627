import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Switch, Route,
} from 'react-router-dom';

import appHeight from './javascripts/documentHeight';

// Components
import MainLoading from './containers/MainLoading';
import Loading from './containers/Fetching';

// Pages
import {
  Home, Login, NotFound,
} from './pages';
// Sub-Pages
import RecoverPwd from './pages/Auth/RecoverPwd';
import NewUser from './pages/Auth/NewUser';

// Assets
import './assets/stylesheets/Main.scss';

// Services
import * as serviceWorker from './serviceWorker';
import storeConfig from './store';
import Agreements from './pages/Agreements';
import SignOut from './pages/Auth/SignOut';

const store = storeConfig();
ReactDOM.render(
  <Provider store={store}>
    <MainLoading />
    <Loading />
    <BrowserRouter>
      <Switch>
        <Route path="/auth">
          <Route path="/auth/recover_password" exact component={RecoverPwd} />
          <Route path="/auth/new_user" exact component={NewUser} />
          <Route path="/auth/sign_out" exact component={SignOut} />
        </Route>
        <Route path="/login" exact component={Login} />
        <Route path="/agreements" exact component={Agreements} />
        <Route path="/" exact component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
appHeight();
