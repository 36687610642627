// LOADING PAGE
export const ADD_LOADING_ITEM = 'ADD_LOADING_ITEM';
export const REMOVE_LOADING_ITEM = 'REMOVE_LOADING_ITEM';
export const GET_LOADING_STAGE = 'GET_LOADING_STAGE';
export const FETCHING = 'FETCHING';

// USER ACTION
export const VALIDATE_USER_LOGIN = 'VALIDATE_USER_LOGIN';
export const VALIDATE_USER_PASSWORD = 'VALIDATE_USER_PASSWORD';
export const AUTHENTICATE_USER_CREDENTIALS = 'AUTHENTICATE_USER_CREDENTIALS';
export const GET_CURRENT_USER_HEADERS = 'GET_CURRENT_USER_HEADERS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const UNVALIDATE_USER_LOGIN = 'UNVALIDATE_USER_LOGIN';

// PERSON ACTION
export const CREATED_PROFILE = 'CREATED_PROFILE';

// COMPANIES ACTION
export const GET_COMPANIES_SUCCESFULL = 'GET_COMPANIES_SUCCESFULL';
export const GET_MORE_COMPANIES_SUCCESFULL = 'GET_MORE_COMPANIES_SUCCESFULL';
export const GET_HERO_COMPANIES_SUCCESFULL = 'GET_HERO_COMPANIES_SUCCESFULL';
export const CREATE_HERO_COMPANIES_SUCCESFULL = 'CREATE_HERO_COMPANIES_SUCCESFULL';
export const UPDATE_HERO_COMPANIES_SUCCESFULL = 'UPDATE_HERO_COMPANIES_SUCCESFULL';
export const DESTROY_HERO_COMPANIES_SUCCESFULL = 'DESTROY_HERO_COMPANIES_SUCCESFULL';

// ERROR MESSAGES
export const FLASH_MESSAGES = 'FLASH_MESSAGES';
export const CLEAR_FLASH_MESSAGES = 'CLEAR_FLASH_MESSAGES';

// SET PARAMS
export const SET_GET_PARAMS = 'SET_GET_PARAMS';

// ADS
export const GET_ADS_SUCCESFULL = 'GET_ADS_SUCCESFULL';
