import React from 'react';
import PropTypes from 'prop-types';

const Options = ({
  data, setInput, setInputValue,
}) => {
  const selectOption = (e) => {
    const { innerText, attributes: { name: { nodeValue } } } = e.currentTarget;
    setInput(innerText);
    setInputValue(nodeValue);
  };

  const options = data.map((option) => (
    <div key={`${option[0]}${option[1]}`} role="presentation" onClick={selectOption} name={option[2]} className="option">
      <strong>
        {option[0]}
      </strong>
      {option[1]}
    </div>
  ));

  return (
    <div className="options">
      {options}
    </div>
  );
};

Options.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  setInput: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
};

export default Options;
