import React from 'react';
import PropTypes from 'prop-types';
import HeroCompany from './HeroCompany';

const HeroCompaniesList = ({ heroCompaniesList, setSelectedCompany }) => {
  if (heroCompaniesList.length === 0) return (null);

  const heroListSubComponents = heroCompaniesList.map((obj) => (
    <HeroCompany
      company={obj}
      setSelectedCompany={setSelectedCompany}
      key={`${obj.name}${obj.id}`}
    />
  ));

  return (
    <div className="hero-companies-list">
      {heroListSubComponents}
    </div>
  );
};

HeroCompaniesList.propTypes = {
  heroCompaniesList: PropTypes.arrayOf(PropTypes.object),
  setSelectedCompany: PropTypes.func.isRequired,
};

HeroCompaniesList.defaultProps = {
  heroCompaniesList: null,
};

export default HeroCompaniesList;
