import * as ActionTypes from './actionsType';

const getCompaniesSuccessful = (json) => ({
  type: ActionTypes.GET_COMPANIES_SUCCESFULL,
  payload: json,
});

const getMoreCompaniesSuccessful = (json) => ({
  type: ActionTypes.GET_MORE_COMPANIES_SUCCESFULL,
  payload: json,
});

const getHeroCompaniesSuccessful = (json) => ({
  type: ActionTypes.GET_HERO_COMPANIES_SUCCESFULL,
  payload: json,
});

const createdHeroCompanySuccessful = (json) => ({
  type: ActionTypes.CREATE_HERO_COMPANIES_SUCCESFULL,
  payload: json,
});

const destroyHeroCompanySuccessful = (id) => ({
  type: ActionTypes.DESTROY_HERO_COMPANIES_SUCCESFULL,
  payload: id,
});

const updatedHeroCompanySuccessful = (json) => ({
  type: ActionTypes.UPDATE_HERO_COMPANIES_SUCCESFULL,
  payload: json,
});

const HeroCompanyError = (json) => ({
  type: ActionTypes.FLASH_MESSAGES,
  payload: { status: 401, msg: json.message },
});

const GetHeroCompanies = () => {
  const url = '/api/v1/companies?hero=1';
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };
  return (dispatch) => {
    fetch(url, config)
      .then((response) => response.json())
      .then((json) => {
        dispatch(getHeroCompaniesSuccessful(json));
        return json;
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
};

const CreateHeroCompany = (params) => {
  const form = new FormData();
  Object.keys(params).forEach((k) => {
    form.append(`company[${k}]`, params[k]);
  });
  const url = '/api/v1/companies';
  const config = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    body: form,
  };
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return ({ response, json });
      })
      .then(({ response, json }) => {
        if (response.status === 201) {
          dispatch(createdHeroCompanySuccessful(json));
        } else {
          dispatch(HeroCompanyError(json));
        }
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
};

const UpdateHeroCompany = (params, id) => {
  const form = new FormData();
  Object.keys(params).forEach((k) => {
    form.append(`company[${k}]`, params[k]);
  });
  const url = `/api/v1/companies/${id}`;
  const config = {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    body: form,
  };
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return ({ response, json });
      })
      .then(({ response, json }) => {
        if (response.status === 201) {
          dispatch(updatedHeroCompanySuccessful(json));
        } else {
          dispatch(HeroCompanyError(json));
        }
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
};

const DeleteHeroCompany = (id) => {
  const url = `/api/v1/companies/${id}`;
  const config = {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return ({ response, json });
      })
      .then(({ response, json }) => {
        if (response.status === 201) {
          dispatch(destroyHeroCompanySuccessful(id));
        } else {
          dispatch(HeroCompanyError(json));
        }
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
};

const GetCompanies = (searchParams = null) => {
  const url = `/api/v1/companies${searchParams ? `?search=${searchParams}` : ''}`;
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };
  return (dispatch) => {
    fetch(url, config)
      .then((response) => response.json())
      .then((json) => {
        dispatch(getCompaniesSuccessful(json));
        return json;
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
};

const GetMoreCompanies = (searchParams = null, offset = null) => {
  const url = `/api/v1/companies${offset ? `?offset=${offset}` : ''}${searchParams ? `&search=${searchParams}` : ''}`;
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };
  return (dispatch) => {
    fetch(url, config)
      .then((response) => response.json())
      .then((json) => {
        dispatch(getMoreCompaniesSuccessful(json));
        return json;
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
};

const SetParams = (params) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_GET_PARAMS,
    payload: params,
  });
};

export {
  GetHeroCompanies, CreateHeroCompany, UpdateHeroCompany,
  DeleteHeroCompany, GetCompanies, GetMoreCompanies, SetParams,
};
