import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Company from './Company';
import { GetMoreCompanies } from '../actions/companiesAction';
import Person from './Person';

const CompaniesList = ({ companies, setCompany }) => {
  const searchParams = useSelector((state) => state.searchParams);

  const [style, setStyle] = useState();
  const dispatch = useDispatch();
  const listOfCompanies = companies
    .map((company) => (
      company.type === 1
        ? (
          <Company
            company={company}
            selected={setCompany}
            key={`${company.name}-${company.id}`}
          />
        )
        : (
          <Person
            person={company}
            selected={setCompany}
            key={`${company.name}-${company.id}`}
          />
        )
    ));

  const pageCounter = useRef(2);
  const eventListeners = useRef();
  const scrollHandler = useCallback((params) => {
    const { current } = pageCounter;
    const scrollMaxY = window.scrollMaxY
      || (document.documentElement.scrollHeight - document.documentElement.clientHeight);

    const currentScroll = document.documentElement.scrollTop;
    if (currentScroll === scrollMaxY) {
      dispatch(GetMoreCompanies(params, current));
      pageCounter.current += 1;
    }
  }, [dispatch]);

  useEffect(() => {
    window.removeEventListener('scroll', eventListeners.current, true);
    eventListeners.current = () => scrollHandler(searchParams);
    window.addEventListener('scroll', eventListeners.current, true);
  }, [companies.length, scrollHandler, searchParams]);

  useEffect(() => {
    setTimeout(() => {
      setStyle({
        height: `${(companies.length * 110) + 150}px`,
      });
    }, 1300);
  });

  return (
    <div className="companies" style={style}>
      {listOfCompanies}
    </div>
  );
};

CompaniesList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCompany: PropTypes.func.isRequired,
};

export default CompaniesList;
