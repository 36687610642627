import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import empty from '../assets/images/empty-profile.png';

const Person = ({ person, selected }) => {
  const {
    profile, name, city, country, profession,
  } = person;

  const [beforeAnimation, setBeforeAnimation] = useState(false);

  const clickHandler = () => {
    selected(null);
    selected(person);
  };

  const changeClass = () => {
    setBeforeAnimation(true);
  };

  return (
    <div
      className={`company${!beforeAnimation ? ' hidden' : ''}`}
      onAnimationStart={changeClass}
      onClick={clickHandler}
      role="presentation"
    >
      <div className="image">
        <img src={profile || empty} alt="logo" />
      </div>
      <div className="info">
        <h2>{name}</h2>
        <p>{profession}</p>
        <span>{`${city} ${country}`}</span>
      </div>
    </div>
  );
};

Person.propTypes = {
  person: PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  selected: PropTypes.func.isRequired,
};

export default Person;
