import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { Link, Redirect } from 'react-router-dom';
import {
  ClearFlashMessage, FlashMessage, SendEmail, AuthResetCode,
} from '../../actions/userAction';

// JavaScript
import Logo from '../../components/Logo';

// Component
import RecoverPwd from '../../components/RecoverPwd';
import RecoveryCode from '../../components/RecoveryCode';
import ResetPwdForm from '../../containers/ResetPwdFrm';

export default function RecoverPassword() {
  const user = useSelector((state) => state.user);
  const msg = useSelector((state) => state.flashMessages);
  const [code, setCode] = useState();
  const dispatch = useDispatch();

  const login = user.email;

  const validationHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const token = data.get('code');

    if (!token) {
      dispatch(FlashMessage(401, 'Code is Required'));
      return;
    }

    if (token.length !== 6) {
      dispatch(FlashMessage(401, 'Invalid Code'));
      return;
    }

    dispatch(AuthResetCode(login, token));
    setCode(token);
    form.reset();
  };

  useEffect(() => {
    if (user.loginAuthenticate) {
      dispatch(SendEmail({ login }));
      setTimeout(() => {
        const newCode = document.getElementById('code');
        if (newCode) {
          newCode.focus();
        }
      }, 1500);
    }
  }, [dispatch, user.loginAuthenticate, login]);

  useEffect(() => {
    dispatch(ClearFlashMessage());
  }, [dispatch]);

  if (user.login) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login" role="presentation">
      <div className="container">
        <Link to="/login" className="forgot-pwd">
          Regresar para iniciar sesión
        </Link>
        <Logo />
        <div className="form-container reset">
          {
            user.loginAuthenticate
              ? null
              : <RecoverPwd msg={msg.msg} />
          }
          {
            user.loginAuthenticate && user.resetEmail
              ? <ResetPwdForm msg={msg.msg} code={code} />
              : null
          }
          {
            user.loginAuthenticate && !user.resetEmail
              ? <RecoveryCode formAction={validationHandler} msg={msg.msg} />
              : null
          }
        </div>
      </div>
    </div>
  );
}
