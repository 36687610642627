let number = -1;

const ConvertToValue = (value, input) => {
  const capitalized = input.slice(0, 1);
  if (capitalized === capitalized.toLowerCase()) {
    return value.toLowerCase();
  }
  return value;
};

const PredictionText = (input, list) => {
  const firstPrediction = [];

  const unfilteredList = list.map((node) => {
    const { id } = node;
    const keys = Object.keys(node);
    const data = node[keys[0]];
    const tempData = data.substring(0, input.length);
    const leftOfData = data.substring(input.length);

    if (data.toLowerCase() === input.toLowerCase()) {
      return null;
    }

    if (tempData.toLowerCase() === input.toLowerCase()) {
      if (input) {
        firstPrediction.push(ConvertToValue(data, input));
      }
      return [tempData, leftOfData, id];
    }

    return null;
  });
  const newList = unfilteredList.filter((e) => e != null);
  return { newList, firstPrediction };
};

const removeOpt = (opts) => {
  for (let i = 0; i < opts.length; i += 1) {
    opts[i].classList.remove('active');
  }
};

const activateOpt = (e, opts) => {
  const div = e.currentTarget.childNodes[3];
  const scroll = div.scrollHeight / opts.length;
  if (number > 0) div.scrollTop = scroll * (number - 1);
  if (number >= div.childNodes.length) div.scrollTop = 0;
  if (!opts || opts.length === 0) return false;
  removeOpt(opts);
  if (number >= opts.length) number = 0;
  if (number < 0) number = (opts.length - 1);
  return opts[number].classList.add('active');
};

const SelectFunctionalities = (e) => {
  const childrens = e.currentTarget.childNodes[3];
  if (!childrens) return;
  const opts = childrens.childNodes;
  if (opts.length === 0) return;
  const key = e.keyCode;

  if (key === 40) {
    number += 1;
    activateOpt(e, opts);
  }
  if (key === 38) {
    number -= 1;
    activateOpt(e, opts);
  }
  if (key === 13 || key === 9) {
    e.preventDefault();
    if (number > -1) {
      if (opts) return opts[number]?.click();
    } else if (opts) return opts[0]?.click();
  }
};
export { PredictionText, SelectFunctionalities };
