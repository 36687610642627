import React from 'react';
import PropTypes from 'prop-types';

import { CurrentSlide } from '../javascripts/slide-show';

const AdsPoinBtns = ({ length }) => {
  const btns = [];

  for (let index = 0; index < length; index += 1) {
    btns.push(
      <button
        className={`dot${index === 0 ? ' active' : ''}`}
        onClick={() => CurrentSlide(index)}
        type="button"
        aria-label="Slider"
        key={`${index}-key`}
      />,
    );
  }

  return (
    <div className="dots-container">
      {btns}
    </div>
  );
};

AdsPoinBtns.propTypes = {
  length: PropTypes.number.isRequired,
};

export default AdsPoinBtns;
