import React from 'react';
import PropTypes from 'prop-types';

export default function FlashMessages({ msg, specialClass }) {
  return (
    <div className={`errors-msg${specialClass && ` ${specialClass}`}`}>
      <span>{msg}</span>
    </div>
  );
}

FlashMessages.propTypes = {
  msg: PropTypes.string.isRequired,
  specialClass: PropTypes.string,
};

FlashMessages.defaultProps = {
  specialClass: '',
};
