import { combineReducers } from 'redux';

import LoadingReducer from './LoadingReducer';
import UserReducer from './UserReducer';
import FlashMessagesReducer from './FlashMessagesReducer';
import FetchingReducer from './FetchingReducer';
import HeroCompaniesReducer from './HeroCompaniesReducer';
import CompaniesReducer from './CompaniesReducer';
import AdsReducer from './AdsReducer';
import SearchReducer from './SearchReducer';

export default combineReducers({
  loadingItems: LoadingReducer,
  user: UserReducer,
  flashMessages: FlashMessagesReducer,
  fetching: FetchingReducer,
  heroCompanies: HeroCompaniesReducer,
  companies: CompaniesReducer,
  ads: AdsReducer,
  searchParams: SearchReducer,
});
