import * as ActionTypes from '../actions/actionsType';

export default (state = '', action) => {
  let newState = 0;
  switch (action.type) {
    case ActionTypes.FETCHING:
      newState = state + 1;
      return newState;
    default:
      if (state > 0) newState = state - 1;
      return newState;
  }
};
