import React from 'react';
import PropTypes from 'prop-types';

const LetterOfAgreements = ({ declined, submit }) => (
  <div className="agreement">
    <div className="container">
      <div className="info">
        <h3>Términos y Condiciones</h3>
        <p>VenEzek proporciona este Directorio como una cortesía para nuestros usuarios.</p>
        <p>
          HIAS
          {' '}
          <strong>no</strong>
          {' '}
          representa ni ofrece garantías de ningún tipo con respecto a las
          compañías contenidas en el Directorio de VenEzek, ni
          {' '}
          <strong>es responsable</strong>
          {' '}
          de la
          exactitud de la información presentada o del valor, calidad o fiabilidad de
          los productos o servicios de las compañías que aparecen en el listado.
        </p>
        <p>
          El vendedor debe indicar su dirección e información de contacto
          si desea que se le incluya en el directorio. VenEzek  sólo usará dicha dirección
          e información de contacto para realizar el registro.
        </p>
      </div>
      <div className="response">
        <button type="button" onClick={submit}>Aceptar</button>
        <button type="button" onClick={() => declined(false)}>Rechazar</button>
      </div>
    </div>
  </div>
);

LetterOfAgreements.propTypes = {
  declined: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default LetterOfAgreements;
