import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { Redirect } from 'react-router-dom';
import {
  LogoutUser, FlashMessage, sendCreateLink, FirstTokenAuth,
} from '../../actions/userAction';

// Component
import CreateNewUser from '../../containers/CreateNewUser';
import NewUserCode from '../../components/NewUserCode';

export default function CreateUser({ location }) {
  const user = useSelector((state) => state.user);
  const msg = useSelector((state) => state.flashMessages);
  const [token, setToken] = useState(null);
  const [redirectHome, setHome] = useState(false);
  const dispatch = useDispatch();

  const newLinkHandler = () => {
    dispatch(sendCreateLink({ login: user.email }));
    dispatch(FlashMessage(201, 'Checkout your email'));
    dispatch(LogoutUser());
  };

  useEffect(() => {
    const parmQuery = new URLSearchParams(location.search);
    const tempToken = parmQuery.get('12kjhsdak');

    setToken(tempToken);
    if (tempToken) {
      dispatch(FirstTokenAuth(tempToken));
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (user.loginAuthenticate) {
      setTimeout(() => {
        const code = document.getElementById('code');
        if (code) {
          code.focus();
        }
      }, 1500);
    }
  }, [dispatch, user.loginAuthenticate]);

  if (redirectHome) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {
        token
          ? <CreateNewUser msg={msg} user={user} token={token} redirect={setHome} />
          : <NewUserCode requestNewLink={newLinkHandler} />
      }
    </>
  );
}

CreateUser.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};
