import React from 'react';
import PropTypes from 'prop-types';
import FlashMessages from './FlashMessages';

export default function RecoveryCode(
  {
    msg, formAction,
  },
) {
  return (
    <div className="cd-frm">
      <h1>Validación de código</h1>
      <h3>Ha recibido un correo electrónico nuestro</h3>
      <form action="/POST" onSubmit={formAction} className="cd-err">
        { msg ? <FlashMessages msg={msg} /> : null}
        <div>
          <div>
            <label htmlFor="login" role="presentation">
              <input type="text" maxLength="6" name="code" id="code" />
            </label>
          </div>
        </div>
        <div className="btns">
          <label htmlFor="submit">
            <input type="submit" value="Verificar" />
          </label>
        </div>
      </form>
    </div>
  );
}

RecoveryCode.propTypes = {
  msg: PropTypes.string,
  formAction: PropTypes.func.isRequired,
};

RecoveryCode.defaultProps = {
  msg: null,
};
