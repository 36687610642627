import React, { useState } from 'react';

import SearchForm from '../containers/SearchForm';
import Img from '../containers/Img';
import empty from '../assets/images/icon.png';

const SearchBar = () => {
  const [beforeAnimation, setBeforeAnimation] = useState(false);
  const changeClass = () => {
    setBeforeAnimation(true);
  };

  return (
    <div className={`search-bar${!beforeAnimation ? ' hidden' : ''}`} onAnimationStart={changeClass}>
      <div className="icon">
        <Img imgUrl={empty} imgAlt="icon" />
      </div>
      <SearchForm />
    </div>
  );
};

SearchBar.propTypes = {
};

export default SearchBar;
