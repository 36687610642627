import * as ActionTypes from './actionsType';

function LoginValidationSucceed(json) {
  return {
    type: ActionTypes.VALIDATE_USER_LOGIN,
    payload: json,
  };
}

function LoginValidationTokenSucceed(json) {
  return {
    type: ActionTypes.AUTHENTICATE_USER_CREDENTIALS,
    payload: json,
  };
}

function LoginUserSucceed(json) {
  return {
    type: ActionTypes.VALIDATE_USER_PASSWORD,
    payload: json,
  };
}

function LoginUserNotValidated(json) {
  return {
    type: ActionTypes.UNVALIDATE_USER_LOGIN,
    payload: json,
  };
}

function LogoutUser() {
  sessionStorage.removeItem('token');
  return {
    type: ActionTypes.LOGOUT_USER,
  };
}

function CloseSession() {
  const url = '/api/auth/sign-in';
  const config = {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return ((dispatch) => {
    fetch(url, config)
      .then(() => dispatch({ type: ActionTypes.LOGOUT_USER }))
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  });
}

function AuthResetCodeSuccess() {
  return {
    type: ActionTypes.SEND_RESET_PASSWORD,
  };
}

const FlashMessage = (status, msg) => ({
  type: ActionTypes.FLASH_MESSAGES,
  payload: { status, msg },
});

const ClearFlashMessage = () => ({
  type: ActionTypes.CLEAR_FLASH_MESSAGES,
});

function GetUserFromToken() {
  const url = '/api/auth';
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return (dispatch) => {
    dispatch({ type: ActionTypes.ADD_LOADING_ITEM });
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return (
          {
            response,
            json,
          }
        );
      })
      .then(({ response, json }) => {
        if (response.status === 200 || response.status === 206) {
          dispatch(ClearFlashMessage());
          dispatch({ type: ActionTypes.ADD_LOADING_ITEM });

          return dispatch(LoginValidationTokenSucceed(json));
        }
        dispatch({ type: ActionTypes.ADD_LOADING_ITEM });
        return dispatch(LogoutUser());
      })
    // eslint-disable-next-line no-console
      .catch(() => dispatch(LogoutUser()));
  };
}

function LoginValidation({ login }) {
  return (dispatch) => {
    const url = `/api/auth/sign-in?login=${login}`;
    const config = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return { json, response };
      })
      .then(({ json, response }) => {
        if (response.status === 200) {
          dispatch(ClearFlashMessage());
          return dispatch(LoginValidationSucceed(json));
        }
        if (response.status === 202) {
          dispatch(ClearFlashMessage());
          return dispatch(LoginUserNotValidated(json));
        }
        return dispatch(FlashMessage(response.status, json.message));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

function LoginUser(credentials) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    const url = '/api/auth/sign-in';
    const config = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    };
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return (
          {
            response,
            json,
          }
        );
      })
      .then(({ response, json }) => {
        if (response.status !== 200) {
          return dispatch(FlashMessage(response.status, json.message));
        }
        return dispatch(LoginUserSucceed(json));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

function SendEmail(login) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    const url = '/api/auth/change-password';
    const config = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(login),
    };
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        if (response.status !== 200) {
          return dispatch(FlashMessage(response.status, json.message));
        }
        return dispatch(FlashMessage(response.status, json.message));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

function AuthResetCode(login, token) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    const url = `/api/auth/change-password?login=${login}&token=${token}`;
    const config = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        if (response.status !== 200) {
          return dispatch(FlashMessage(response.status, json.error));
        }
        dispatch(ClearFlashMessage());
        return dispatch(AuthResetCodeSuccess(json));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

function changePwd(params) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    const url = '/api/auth/change-password/';
    const config = {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return (
          {
            response,
            json,
          }
        );
      })
      .then(({ response, json }) => {
        if (response.status !== 200) {
          return dispatch(FlashMessage(response.status, json.message));
        }
        return dispatch(LoginUserSucceed(json));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

function sendCreateLink(params) {
  return () => {
    const url = '/api/auth/complete-registration';
    const config = {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };
    fetch(url, config)
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

function FirstTokenAuth(token) {
  const url = '/api/auth/complete-registration';
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return (
          {
            response,
            json,
          }
        );
      })
      .then(({ response, json }) => {
        if (response.status === 200) {
          return dispatch(LoginValidationSucceed(json));
        }
        if (json.message === 'Invalid token age') {
          dispatch(FlashMessage(response.status, 'Please Request a New Token'));
        }
        return dispatch(LogoutUser(json));
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

function FirstLogin(token, body, redirect) {
  const url = '/api/auth/complete-registration';
  const config = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return (
          {
            response,
            json,
          }
        );
      })
      .then(({ response, json }) => {
        if (response.status === 201) {
          dispatch(FlashMessage(response.status, 'User Creation Succeed'));
          dispatch(LoginUserSucceed(json));
          return redirect(true);
        }
        return dispatch(FlashMessage(response.status, json.message));
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };
}

export {
  LoginValidation, ClearFlashMessage, FlashMessage, LoginUser,
  LogoutUser, GetUserFromToken, SendEmail, AuthResetCode, changePwd,
  sendCreateLink, FirstTokenAuth, FirstLogin, CloseSession,
};
