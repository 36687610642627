const getProfessions = (category) => {
  const url = `/api/v1/professions?category=${category}`;
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return (
    fetch(url, config)
      .then((response) => {
        if (response.status !== 200) {
          return [];
        }
        return response.json();
      })
      .then((json) => json)
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Error Fetching Countries');
      })
  );
};

export default getProfessions;
