import * as ActionTypes from './actionsType';

const CreateProfile = (content) => {
  const form = new FormData();
  Object.keys(content).forEach((k) => {
    form.append(k, content[k]);
  });
  const url = '/api/v1/people';
  const config = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    body: form,
  };
  return ((dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return ({ response, json });
      })
      .then(({ response, json }) => {
        if (response.status === 201) {
          dispatch({
            type: ActionTypes.CREATED_PROFILE,
            payload: { ...json, status: 201 },
          });
        } else {
          dispatch({
            type: ActionTypes.FLASH_MESSAGES,
            payload: { status: 401, msg: json.message },
          });
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  });
};

const UpdateProfile = (content) => {
  const form = new FormData();
  Object.keys(content).forEach((k) => {
    form.append(k, content[k]);
  });
  const url = '/api/v1/people/1';
  const config = {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    body: form,
  };
  return ((dispatch) => {
    dispatch({ type: ActionTypes.FETCHING });
    fetch(url, config)
      .then(async (response) => {
        const json = await response.json();
        return ({ response, json });
      })
      .then(({ response, json }) => {
        if (response.status === 201) {
          dispatch({
            type: ActionTypes.CREATED_PROFILE,
            payload: { ...json, status: 201 },
          });
        } else {
          dispatch({
            type: ActionTypes.FLASH_MESSAGES,
            payload: { status: 401, msg: json.message },
          });
        }
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  });
};

const GetProfile = () => {
  const url = '/api/v1/people/1';
  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };
  return (
    fetch(url, config)
      .then((response) => response.json())
      .then((json) => json)
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
  );
};

export { CreateProfile, GetProfile, UpdateProfile };
