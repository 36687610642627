import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

// Components
import GetEmail from '../components/GetEmail';
import LoginUser from '../containers/LoginUser';
import Logo from '../components/Logo';

export default function Login() {
  const user = useSelector((state) => state.user);
  const msg = useSelector((state) => state.flashMessages);

  if (user.login) {
    return <Redirect to="/" />;
  }

  if (user.loginAuthenticate && !user.validated) {
    return <Redirect to="/auth/new_user" />;
  }

  return (
    <div className="login" role="presentation">
      <div className="container">
        <Logo />
        <div className="form-container">
          {
             user.loginAuthenticate
               ? <LoginUser msg={msg.msg} />
               : <GetEmail msg={msg.msg} />
          }
        </div>
        <Link to="/auth/recover_password" className="forgot-pwd">
          ¿Se te olvidó tu contraseña?
        </Link>
      </div>
    </div>
  );
}
