/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes, { oneOfType } from 'prop-types';

// Actions
import {
  ClearFlashMessage, FlashMessage, FirstLogin,
} from '../actions/userAction';

// JavaScript
import focusText from '../javascripts/focusText';

// Component
import Logo from '../components/Logo';
import FlashMessages from '../components/FlashMessages';
import LetterOfAgreements from '../components/LetterOfAgreements';

export default function CreateNewUser({
  msg, user, token, redirect,
}) {
  const [username, setUsername] = useState('');
  const [pwd, setPwd] = useState('');
  const [pwdConfi, setPwdConfi] = useState('');
  const dispatch = useDispatch();
  const [openAgreement, setOpenAgreement] = useState(false);
  const [values, setValues] = useState();

  const formValidation = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const tempUsername = data.get('username');
    const password = data.get('password');
    const password_confirmation = data.get('password_confirmation');
    if (!password || !password_confirmation || !tempUsername) {
      dispatch(FlashMessage(401, 'Todos los campos son obligatorios'));
    } else if (password !== password_confirmation) {
      dispatch(FlashMessage(401, 'Las contraseñas no coinciden'));
    } else {
      setValues({ username: tempUsername, password, password_confirmation });
      setOpenAgreement(true);
    }
  };

  const submitForm = () => {
    setOpenAgreement(false);
    dispatch(FirstLogin(token, values, redirect));
  };

  const usernameHandler = (e) => {
    setUsername(e.target.value);
  };

  const pwdHandler = (e) => {
    setPwd(e.target.value);
  };

  const pwdConfiHandler = (e) => {
    setPwdConfi(e.target.value);
  };

  useEffect(() => {
    dispatch(ClearFlashMessage());
  }, [dispatch]);

  useEffect(() => {
    if (user.loginAuthenticate) {
      setTimeout(() => {
        const code = document.getElementById('code');
        if (code) {
          code.focus();
        }
      }, 1500);
    }
  }, [dispatch, user.loginAuthenticate]);

  return (
    <div className={`login welcome${msg ? ' err' : ''}`} role="presentation">
      {openAgreement
        ? <LetterOfAgreements declined={setOpenAgreement} submit={submitForm} />
        : null}
      <div className="container">
        <Logo />
        <h1>¡Qué bien, eres tú!</h1>
        <h3>Crea tu cuenta</h3>
        { msg ? <FlashMessages msg={msg.msg || ''} /> : null}
        <div className="form-container">
          <form action="/POST" className="new-user" onSubmit={formValidation}>
            <div>
              <label htmlFor="username" className={`info${username ? ' on' : ''}`} onClick={focusText} role="presentation">
                <span>Usuario</span>
                <input type="text" name="username" id="username" disabled={!user.loginAuthenticate} onChange={usernameHandler} />
              </label>
            </div>
            <div>
              <label htmlFor="password" className={`info${pwd ? ' on' : ''}`} onClick={focusText} role="presentation">
                <span>Contraseña</span>
                <input type="password" name="password" id="password" disabled={!user.loginAuthenticate} onChange={pwdHandler} />
              </label>
            </div>
            <div>
              <label htmlFor="password_confirmation" className={`info${pwdConfi ? ' on' : ''}`} onClick={focusText} role="presentation">
                <span>Confirmación de contraseña</span>
                <input type="password" name="password_confirmation" id="password_confirmation" disabled={!user.loginAuthenticate} onChange={pwdConfiHandler} />
              </label>
            </div>
            <div className="btns">
              <label htmlFor="submit">
                <input type="submit" value="Crear!" />
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

CreateNewUser.propTypes = {
  msg: PropTypes.objectOf(oneOfType([PropTypes.number, PropTypes.string])),
  token: PropTypes.string,
  user: PropTypes.objectOf(oneOfType([PropTypes.bool, PropTypes.string])).isRequired,
  redirect: PropTypes.func.isRequired,
};

CreateNewUser.defaultProps = {
  msg: null,
  token: null,
};
