import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AdsSliderElement from './AdsSliderElement';
import AdsPointBtns from './AdsPointBtns';

import { ShowSlides, TimingSlides, CurrentSlide } from '../javascripts/slide-show';
import Swipe from '../javascripts/swiped-event';

const AdsSliderContainer = ({ arrayOfAds }) => {
  useEffect(() => {
    TimingSlides();
    CurrentSlide(0);

    const e = document.getElementsByClassName('slider')[0];
    const swipe = new Swipe(e);
    swipe.onLeft(() => ShowSlides(0));
    swipe.onRight(() => ShowSlides(-2));
    swipe.run();
  }, [arrayOfAds]);

  const listOfAds = arrayOfAds.map((ad, i) => (
    <AdsSliderElement
      imgSrc={ad.ads}
      link={ad.link}
      refClass={i === 0 ? ' active' : ''}
      key={`${ad.id}-ad-key`}
    />
  ));

  return (
    <>
      <div className="slider">
        {listOfAds}
        <AdsPointBtns length={arrayOfAds.length} />
      </div>
      <div className="slider-changer">
        <button type="button" className="prev" onClick={() => ShowSlides(-2)}>&#10094;</button>
        <button type="button" className="next" onClick={() => ShowSlides(0)}>&#10095;</button>
      </div>
    </>
  );
};

AdsSliderContainer.propTypes = {
  arrayOfAds: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AdsSliderContainer;
