/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import empty from '../assets/images/empty-logo.jpg';

const ImageUploader = ({ setFile, img, refClass }) => {
  const [imgUrl, setImgUrl] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const type = file.type.split('/').shift();
      if (type === 'image') {
        const reader = new FileReader();
        reader.onload = () => {
          setFile(file);
          setImgUrl(URL.createObjectURL(file));
        };
        reader.readAsArrayBuffer(file);
      }
    });
  }, [setFile]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const clickHandler = (e) => {
    const input = e.currentTarget.childNodes[0];
    input.click();
  };

  useEffect(() => {
    setImgUrl(img);
  }, [img]);

  return (
    <div className={`hero-img${refClass}`}>
      <div {...getRootProps()} className="container" role="presentation" onClick={clickHandler}>
        <input {...getInputProps()} name="file" />
        <div className="text-container">
          <p>Arrastre y suelte</p>
          <p>512px x 512px</p>
        </div>
        <img src={imgUrl || empty} height="150" alt="Your Selfie" />
      </div>
    </div>
  );
};

ImageUploader.propTypes = {
  img: PropTypes.string,
  refClass: PropTypes.string,
  setFile: PropTypes.func.isRequired,
};

ImageUploader.defaultProps = {
  img: null,
  refClass: null,
};

export default ImageUploader;
